<script setup lang="ts">
import { header } from './headerStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof header
const { $auth } = useNuxtApp()

const { base, logoutButton, logoutIcon, background, container, logo, link } =
  header[theme]()
</script>

<template>
  <div :class="background()">
    <div :class="container()">
      <header :class="base()">
        <CPLink :to="whitelabelLink('/')" class="flex flex-row items-end">
          <NuxtImg
            preload
            :class="logo()"
            src="/small_logo.svg"
            alt="Logo"
            height="50"
          />
          <div :class="link()">{{ $t('cp.header.return-to-website') }}</div>
        </CPLink>

        <CPButton
          v-if="$auth.loggedIn.value"
          unstyled
          :class="logoutButton()"
          @click="$auth.signOut()"
        >
          <template #leading-icon>
            <Icon :class="logoutIcon()" name="material-symbols:logout" />
          </template>
          {{ $t('nav.logout') }}
        </CPButton>
      </header>
    </div>
  </div>
</template>
