<script setup lang="ts">
import type { NuxtLinkProps } from '#app'
import { link } from './linkStyles'

interface LinkProps {
  size?: 'sm' | 'md' | 'lg'
}

const props = defineProps<NuxtLinkProps & LinkProps>()

const theme = useRuntimeConfig().public.theme as keyof typeof link
const base = link[theme]

const ui = base({
  size: props.size,
  class: useAttrs().class as string,
})
</script>

<template>
  <NuxtLink :class="ui" :to="to" :target="target">
    <slot name="leading" />
    <slot />
    <slot name="trailing" />
  </NuxtLink>
</template>
