import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { paymentRateStatus } from '~/enums'
import type { BookingDetails } from '~/types/Booking'

interface PaymentMethod {
  name: string | null
  description: string | null
  paymentProviderIdentifier: string
  paymentMethodIdentifier: string | null
  feePercentage: number
}

export const usePortalPaymentStore = defineStore('portal-payment', () => {
  const toast = useToast()
  const { t } = useNuxtApp().$i18n

  const paymentMethods = ref<PaymentMethod[]>([])
  const selectedPaymentAmount = ref(0)
  const selectedPaymentMethod = ref<PaymentMethod | null>(null)
  const giftVoucher = ref('')
  const loading = ref(false)

  const fetchPaymentProviders = async () => {
    try {
      const response = await $twenty<PaymentMethod[]>(`payment/providers`)

      if (response && response.length) {
        selectedPaymentMethod.value = response[0]
      }

      paymentMethods.value = response ? response : []

      return response
    } catch (error) {
      toast.error(t('toast-message.generic-error'))
    }
  }

  const payment = async (bookingRef: string, amount: number) => {
    loading.value = true
    switch (selectedPaymentMethod.value?.paymentProviderIdentifier) {
      case 'bambora':
        bamboraPayment(bookingRef, amount)
        break

      case 'saferpay':
        saferpayPayment(bookingRef, amount)
        break

      case 'gift_voucher':
        giftVoucherPayment()
        break
    }
  }

  const bamboraPayment = async (bookingRef: string, amount: number) => {
    const domain = useNuxtApp().$i18n.localeProperties.value.domain
    const apiLocale = useNuxtApp().$i18n.localeProperties.value.apiLocale
    const { bookingId } = useRoute().params
    const localePath = useLocalePath()
    const redirectUrl = localePath({
      name: 'profile-bookings-bookingId',
      params: { bookingId: bookingId },
    })

    try {
      const response = await $twenty('customer/payment/getOrderId', {
        method: 'POST',

        body: {
          ref: bookingRef,
          slug: 'bambora',
          amount: amount,
        },
      })

      const payment = {
        ...response,
        language: 1,
        amount: Math.round(response.amount * 100),
        merchantnumber: response.merchantId,
        accepturl: `${domain}/bambora?orderIdHash=${response.orderIdHash}&apiLocale=${apiLocale}&redirectUrl=${redirectUrl}&bookingId=${bookingId}`,
        callbackurl: `${domain}/bambora?orderIdHash=${response.orderIdHash}&apiLocale=${apiLocale}&redirectUrl=${redirectUrl}&bookingId=${bookingId}`,
        cancelurl: `${domain}${redirectUrl}`,
        ownreceipt: 1,
      }
      useGaSharedFunction.gtagEvent('customer_portal_payment_start', {
        booking_id: bookingRef,
      })
      new PaymentWindow(payment).open()
    } catch (error) {
      toast.error(t('toast-messages.payment-fail'))
    } finally {
      loading.value = false
    }
  }

  const saferpayPayment = async (bookingRef: string, amount: number) => {
    const { bookingId } = useRoute().params
    const domain = useNuxtApp().$i18n.localeProperties.value.domain
    const apiLocale = useNuxtApp().$i18n.localeProperties.value.apiLocale
    const localePath = useLocalePath()
    const redirectUrl = localePath({
      name: 'profile-bookings-bookingId',
      params: { bookingId: bookingId },
    })

    try {
      const response = await $twenty('customer/payment/getOrderId', {
        method: 'POST',
        body: {
          paymentMethod: selectedPaymentMethod.value?.paymentMethodIdentifier,
          ref: bookingRef,
          slug: 'saferpay',
          amount: amount,
          successUrl: `${domain}/saferpay?apiLocale=${apiLocale}&redirectUrl=${redirectUrl}&bookingId=${bookingId}`,
        },
      })
      useGaSharedFunction.gtagEvent('customer_portal_payment_start', {
        booking_id: bookingRef,
      })
      return navigateTo(response.saferpayUrl, {
        external: true,
      })
    } catch (error) {
      toast.error(t('toast-messages.payment-fail'))
    } finally {
      loading.value = false
    }
  }

  const giftVoucherPayment = async () => {
    // TODO
  }

  const getShowPaymentRates = (booking: BookingDetails) => {
    return (
      booking.paymentRates.filter(
        (rate) => rate.status === paymentRateStatus.pending
      ).length > 1
    )
  }

  const getShowGiftVoucher = computed(
    () =>
      selectedPaymentMethod.value?.paymentProviderIdentifier === 'gift_voucher'
  )

  return {
    paymentMethods,
    selectedPaymentAmount,
    selectedPaymentMethod,
    giftVoucher,
    loading,
    fetchPaymentProviders,
    payment,
    getShowPaymentRates,
    getShowGiftVoucher,
  }
})
