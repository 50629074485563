import { defineStore } from 'pinia'
import type {
  bookingFormularCountries,
  Configuration,
  PhonePrefix,
} from '../../websites/types/Configurations'
import type { AnalyticsData, NavItem } from '../../websites/types/Navigation'

interface State {
  configs: Configuration
  analyticsData: AnalyticsData
  phonePrefixes: PhonePrefix[]
  bookingFormularCountries: bookingFormularCountries[]
  defaultCalenderDate?: string
  defaultFilterpageDate: string | null
  footer: NavItem[]
  notTranslated: string[]
}

export const useConfigStore = defineStore({
  id: 'config',
  state: (): State => {
    return {
      footer: [],
      configs: {
        locale: '',
        brand: '',
        currency: '',
        safetyIcons: {
          footer: {
            center: '',
            payment: [],
            security: [],
          },
          headerLeft: [],
          headerRight: [],
        },
        socialIcons: [],
        sortingOptions: {
          accommodations: [],
          reviewSorting: [],
          sites: [],
          reviewGroupSorting: [],
        },
      },
      analyticsData: {
        season: {
          current: {
            type: '',
            year: '',
          },
          next: {
            year: '',
          },
        },
      },
      phonePrefixes: [],
      bookingFormularCountries: [],
      defaultFilterpageDate: null,
      notTranslated: [],
    }
  },
  actions: {
    updateNotTranslated(locale: string) {
      this.notTranslated.push(locale)
    },
  },
  getters: {},
})
