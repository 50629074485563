export default defineNuxtPlugin(() => {
  // page_load is fired only on the client after each page has finished rendering
  useNuxtApp().hook('page:finish', () => {
    const route = useRoute()
    const type = route.meta.cpAnalyticsType
    const bookingRef = useState('bookingRef')

    if (type)
      useGaSharedFunction.gtagEvent('customer_portal_page_view', {
        page_type: type,
        booking_id: route.params.bookingId ? bookingRef.value : undefined,
      })
  })
})
