import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig()
  const { brand } = useAppConfig()
  const router = useRouter()
  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    release: sentry.release,
    debug: sentry.environment === 'local',
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    enabled: sentry.enabled === 'true' ? true : false,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: [brand],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    tracesSampleRate: 0.05, // Change in prod if necessary
    replaysOnErrorSampleRate: 0.05, // Change in prod if necessary
  })
})
