import { tv } from 'tailwind-variants'

export const link = {
  alpha: tv({
    base: 'text-brand-secondary-400 w-fit flex flex-row gap-1 items-center cursor-pointer hover:text-brand-secondary-500',
    variants: {
      size: {
        sm: 'text-sm',
        md: 'text-md',
        lg: 'text-lg',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }),
  bravo: tv({
    base: 'text-brand-secondary-400 w-fit flex flex-row gap-1 items-center cursor-pointer hover:text-brand-secondary-500',
    variants: {
      size: {
        sm: 'text-sm',
        md: 'text-md',
        lg: 'text-[18px] font-light',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }),
  charlie: tv({
    base: 'w-fit flex flex-row gap-1 items-center',
    variants: {
      size: {
        sm: 'font-link12',
        md: 'font-link14',
        lg: 'font-link16',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }),
}
