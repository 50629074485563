import type { NitroFetchOptions } from 'nitropack'

export default async function $twenty<T>(
  url: string,
  opts?: NitroFetchOptions<string>,
  apiVersion?: string
) {
  const { $twentyv1, $twentyv2 } = useNuxtApp()
  return apiVersion === 'v1' ? $twentyv1<T>(url, opts) : $twentyv2<T>(url, opts)
}
