
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as login2yA8Kq2SBkMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/login.vue?macro=true";
import { default as indexz9unoTIzstMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue?macro=true";
import { default as indexxSSzWQKTFrMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/index.vue?macro=true";
import { default as participants0zELQ3nPX1Meta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/participants.vue?macro=true";
import { default as indexkAtiE3B3JhMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue?macro=true";
import { default as optionsDPwg75zqqOMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue?macro=true";
import { default as indextN5vXoPgu3Meta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/index.vue?macro=true";
import { default as detailsoboOLgPQJHMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/details.vue?macro=true";
import { default as favoritesxrP4nGmSvXMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/favorites.vue?macro=true";
import { default as indexdWWkz7IoIEMeta } from "/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/index.vue?macro=true";
export default [
  {
    name: "login___da",
    path: "/login",
    meta: login2yA8Kq2SBkMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/login.vue")
  },
  {
    name: "profile-bookings-bookingId-extras___da",
    path: "/profile/bookings/:bookingId()/extras",
    meta: indexz9unoTIzstMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue")
  },
  {
    name: "profile-bookings-bookingId___da",
    path: "/profil/bookinger/:bookingId()",
    meta: indexxSSzWQKTFrMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/index.vue")
  },
  {
    name: "profile-bookings-bookingId-participants___da",
    path: "/profile/bookings/:bookingId()/participants",
    meta: participants0zELQ3nPX1Meta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/participants.vue")
  },
  {
    name: "profile-bookings-bookingId-payment___da",
    path: "/profil/bookinger/:bookingId()/betaling",
    meta: indexkAtiE3B3JhMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue")
  },
  {
    name: "profile-bookings-bookingId-payment-options___da",
    path: "/profil/bookinger/:bookingId()/betaling/metoder",
    meta: optionsDPwg75zqqOMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue")
  },
  {
    name: "profile-bookings___da",
    path: "/profil/bookinger",
    meta: indextN5vXoPgu3Meta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/bookings/index.vue")
  },
  {
    name: "profile-details___da",
    path: "/profil/detaljer",
    meta: detailsoboOLgPQJHMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/details.vue")
  },
  {
    name: "profile-favorites___da",
    path: "/profil/favoritter",
    meta: favoritesxrP4nGmSvXMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/favorites.vue")
  },
  {
    name: "profile___da",
    path: "/profil",
    meta: indexdWWkz7IoIEMeta || {},
    component: () => import("/srv/www/dune-cp-dbf/releases/20250324114105/customer-portal/pages/profile/index.vue")
  }
]