export default defineNuxtRouteMiddleware((to) => {
  const { isWhitelabelBrand } = useRuntimeConfig().public

  const localePath = useLocalePath()

  if (to.path === '/' && isWhitelabelBrand) {
    return navigateTo(localePath('/login'), {
      external: true,
    })
  }
})
