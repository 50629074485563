import { defineNuxtPlugin } from 'nuxt/app'
import type { FetchOptions } from 'ofetch'

export default defineNuxtPlugin((nuxtApp) => {
  const { AUTH_HEADER_NAME, brand } = useRuntimeConfig().public
  // @ts-expect-error - This is a hack to ignore the type of localeProperties
  const { localeProperties } = nuxtApp.$i18n

  // get token from cookie
  const token = useState('token')

  // set token cookie on outgoing requests if token is present
  const defaults: FetchOptions = {
    onRequest({ options }) {
      if (token.value) {
        options.headers.set(AUTH_HEADER_NAME, `Bearer ${token.value}`)
      }
    },
    async onResponseError({ response, options }) {
      if (response.status === 404 && !options.ignoreResponseError) {
        await nuxtApp.runWithContext(() => {
          showError({
            statusCode: response.status,
            statusMessage: response.statusText,
          })
        })
      }
    },
  }

  // create fetch instances for each version of the API
  const twentyv1 = $fetch.create({
    ...defaults,
    baseURL: `/api/twenty/v1/${brand}/${localeProperties.value.apiLocale}`,
  })

  const twentyv2 = $fetch.create({
    ...defaults,
    baseURL: `/api/twenty/v2/${brand}/${localeProperties.value.apiLocale}`,
  })

  // provide the fetch instances to nuxtApp
  return {
    provide: {
      twentyv1,
      twentyv2,
    },
  }
})
