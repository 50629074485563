
// @ts-nocheck


export const localeCodes =  [
  "da"
]

export const localeLoaders = {
  "da": [{ key: "../../configurations/danskbilferie/locales/da_DK.ts", load: () => import("../../configurations/danskbilferie/locales/da_DK.ts?hash=f99fb98e&locale=da" /* webpackChunkName: "locale__srv_www_dune_cp_dbf_releases_20250324114105_configurations_danskbilferie_locales_da_DK_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "apiLocale": "dk",
      "language": "da-DK",
      "domain": "https://portal.danskbilferie.dk",
      "whitelabel": "https://danskbilferie.dk",
      "googleMapsApiKey": "",
      "googleMapsMapId": "",
      "code": "da",
      "calenderLocale": "da",
      "locale": "da-DK",
      "flagCode": "DK",
      "cookieLanguage": "da",
      "currencyFormatterIso": "da",
      "name": "Danmark",
      "market": "DBF",
      "files": [
        "/srv/www/dune-cp-dbf/releases/20250324114105/configurations/danskbilferie/locales/da_DK.ts"
      ]
    }
  ],
  "defaultLocale": "da",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://portal.danskbilferie.dk",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "themes/index": {
      "da": "/tema"
    },
    "themes/[slug]/index": {
      "da": "/tema/[slug]"
    },
    "profile/index": {
      "da": "/profil"
    },
    "profile/favorites": {
      "da": "/profil/favoritter"
    },
    "profile/details": {
      "da": "/profil/detaljer"
    },
    "profile/bookings/index": {
      "da": "/profil/bookinger"
    },
    "profile/bookings/[bookingId]/index": {
      "da": "/profil/bookinger/[bookingId]"
    },
    "profile/bookings/[bookingId]/payment/index": {
      "da": "/profil/bookinger/[bookingId]/betaling"
    },
    "profile/bookings/[bookingId]/payment/options": {
      "da": "/profil/bookinger/[bookingId]/betaling/metoder"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "apiLocale": "dk",
    "language": "da-DK",
    "domain": "https://portal.danskbilferie.dk",
    "whitelabel": "https://danskbilferie.dk",
    "googleMapsApiKey": "",
    "googleMapsMapId": "",
    "code": "da",
    "calenderLocale": "da",
    "locale": "da-DK",
    "flagCode": "DK",
    "cookieLanguage": "da",
    "currencyFormatterIso": "da",
    "name": "Danmark",
    "market": "DBF",
    "files": [
      {
        "path": "/srv/www/dune-cp-dbf/releases/20250324114105/configurations/danskbilferie/locales/da_DK.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
