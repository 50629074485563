import type { Pinia } from 'pinia'
import type { NavigationGroupResponse } from '../../websites/types/Navigation'

export default defineNuxtPlugin(async () => {
  const { isWhitelabelBrand } = useRuntimeConfig().public
  if (isWhitelabelBrand) {
    const pinia = usePinia() as Pinia
    const configStore = useConfigStore()
    const { $i18n } = useNuxtApp()
    const localeCode = $i18n.locale.value
    const appConfig = useAppConfig()

    const piniaPlugin = () => {
      return {
        t: $i18n.t,
      }
    }

    pinia.use(piniaPlugin)

    const { data } = await useTwentyFetch<NavigationGroupResponse>(
      'navigation/groups',
      'v2',
      {
        key: 'navigation-groups',
      }
    )

    configStore.configs = appConfig.domainConfigurations[localeCode]

    if (data.value?.footer) {
      configStore.footer = data.value?.footer.length ? data.value.footer[0] : []
    }

    const phonePrefixes = data.value?.phonePrefixes
    const bookingFormularCountries = data.value?.countries

    configStore.phonePrefixes = phonePrefixes ?? configStore.phonePrefixes
    configStore.bookingFormularCountries =
      bookingFormularCountries ?? configStore.bookingFormularCountries
  }
})
