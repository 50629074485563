import { useToast } from 'vue-toastification'
import type { H3Error } from 'h3'
import { AuthType, LoginView, UserType } from '@CPEnums'

interface Credentials {
  firstName?: string
  lastName?: string
  email: string
  password: string
}

interface Country {
  id: number | null
  name: string
  code: string
}

interface UserInfo {
  [key: string]: string | number | undefined | Country | boolean | number[]
  email?: string
  favoriteSites?: number[]
  firstName?: string
  lastName?: string
  birthdate?: string
  phonePrefix?: string
  phone?: string
  country?: Country
  countryId?: number
  city?: string
  zip?: string
  address1?: string
  address2?: string
  isVerified?: boolean
  userType?: UserType
  authType?: AuthType
  bookingId?: string
  editCustomerEnabled: boolean
}

interface AuthResponse {
  token: string
}

export default defineNuxtPlugin(async () => {
  const { AUTH_HEADER_NAME } = useRuntimeConfig().public
  const localePath = useLocalePath()
  const user = useState<UserInfo | null>('user')
  const { t } = useNuxtApp().$i18n
  const toast = useToast()
  const loading = ref(false)
  const loginPage = useState('loginPage')
  const loginTransition = useState('loginTransition')
  const bookingRef = useState('bookingRef')
  const loginType = useCookie('loginType', { default: () => '' })

  const tokenCookie = useCookie('token', {
    secure: true,
  })

  const token = useState('token', () => tokenCookie.value)

  const getUserStatus = async (email: string) => {
    try {
      loading.value = true
      await $twenty('customer-portal/status', {
        method: 'POST',
        body: { email },
        ignoreResponseError: true,
        onResponse({ response }) {
          if (response.status === 404) {
            loginPage.value = LoginView.createPassword
          }

          if (response.status === 204) {
            loginPage.value = LoginView.password
          }

          if (response.status === 429) {
            loginPage.value = LoginView.signInAttemptsExceeded
          }

          if (response.status === 401) {
            loginPage.value = LoginView.checkInbox
          }
        },
      })
    } finally {
      loading.value = false
    }
  }

  const { refresh: refreshUser } = await useAsyncData(async () => {
    const apiLocale = useNuxtApp().$i18n.localeProperties.value
      .apiLocale as string
    const response = await $fetch<UserInfo>(`/api/${apiLocale}/user`, {
      headers: {
        [AUTH_HEADER_NAME]: `Bearer ${token.value}`,
      },
      onResponse({ response }) {
        if (response.status === 401) {
          setTokenCookie(null)
        }
      },
    })

    user.value = response

    return response
  })

  const updateUserInfo = async (
    body: UserInfo,
    changedDetails: Array<string>
  ) => {
    try {
      loading.value = true
      await $twenty<AuthResponse>('customer-portal', {
        body: body,
        method: 'PUT',
      })
      await refreshUser()
      toast.success(t(`cp.edit-user-profile.toast.success`))
      useGaSharedFunction.gtagEvent(
        'customer_portal_change_account_details_complete',
        {
          changed_detail: changedDetails && changedDetails,
        }
      )
    } catch {
      toast.error(t(`cp.edit-user-profile.toast.error`))
    } finally {
      loading.value = false
    }
  }

  const signInWithEmailPassoword = async (credentials: Credentials) => {
    try {
      loading.value = true
      const response = await $twenty<AuthResponse>(
        'customer-portal/authenticate',
        {
          body: credentials,
          method: 'POST',
        }
      )
      setTokenCookie(response.token)
      refreshUser()
      toast.success(t('toast-messages.login-success'))
      useGaSharedFunction.gtagEvent('customer_portal_login', {
        loginType: (loginType.value = 'email'),
      })
      return response
    } catch (error) {
      toast.error(t('toast-messages.login-fail'))
    } finally {
      loading.value = false
    }
  }

  const signInWithBookingRefEmail = async (
    email: string,
    bookingRef: string
  ) => {
    try {
      loading.value = true
      const response = await $twenty<{ token: string }>(
        '/customer-portal/authenticate-booking',
        {
          body: { email, bookingRef },
          method: 'POST',
        }
      )

      setTokenCookie(response.token)

      await refreshUser()

      toast.success(t('toast-messages.login-success'))
      useGaSharedFunction.gtagEvent('customer_portal_login', {
        loginType: (loginType.value = 'booking_id'),
      })
      return response
    } catch (error) {
      toast.error(t('toast-messages.login-fail'))
    } finally {
      loading.value = false
    }
  }

  const forgotBookingReference = async (email: string) => {
    try {
      loading.value = true
      await $twenty<AuthResponse>(
        'customer-portal/user/forgot-booking-credentials',
        {
          body: { email },
          method: 'POST',
        }
      )

      toast.success(t('toast-messages.send-confirmation-success'))
      loginTransition.value = 'right'
      loginPage.value = LoginView.signIn
    } catch (error) {
      toast.error(t('toast-messages.send-confirmation-fail'))
    } finally {
      loading.value = false
    }
  }

  const signUp = async (credentials: Credentials) => {
    try {
      loading.value = true
      const response = await $twenty('customer-portal', {
        method: 'POST',
        body: credentials,
      })

      loginPage.value = LoginView.checkInbox
      useGaSharedFunction.gtagEvent('account_create', {
        user: {
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: credentials.email,
        },
      })
      return response
    } catch (error) {
      if ((error as H3Error).statusCode === 409)
        toast.error(t('toast-messages.email-in-use'))
      else toast.error(t('toast-messages.login-fail'))
    } finally {
      loading.value = false
    }
  }

  const resetPassword = async (
    token: string,
    password: string,
    confirmedPassword: string
  ) => {
    try {
      loading.value = true
      await $twenty('customer-portal/reset/password', {
        method: 'POST',
        headers: {
          [useRuntimeConfig().public.AUTH_HEADER_NAME]: `Bearer ${token}`,
        },
        body: JSON.stringify({
          token: token,
          password: password,
          confirmed_password: confirmedPassword,
        }),
      })

      toast.success(t('toast-messages.password-update-success'))
      loginTransition.value = 'right'
      loginPage.value = LoginView.signIn
      await navigateTo(localePath('/login'))
    } catch (error) {
      toast.error(t('toast-messages.password-update-fail'))
    } finally {
      loading.value = false
    }
  }

  const getResetPasswordLink = async (email: string) => {
    try {
      loading.value = true
      const response = await $twenty('customer-portal/reset/link', {
        body: JSON.stringify({ email: email }),
        method: 'POST',
      })

      toast.success(t('toast-messages.password-send-success'))
      loginTransition.value = 'right'
      loginPage.value = LoginView.signIn
      return response
    } catch (error) {
      toast.error(t('toast-messages.password-send-fail'))
    } finally {
      loading.value = false
    }
  }

  const signOut = async () => {
    setTokenCookie(null)
    useGaSharedFunction.gtagEvent('customer_portal_logout', {
      login_type: loginType.value,
      booking_id: bookingRef.value,
    })
    await navigateTo('/', {
      external: true,
    })
  }

  if (tokenCookie.value) {
    await refreshUser()
  }

  const setTokenCookie = (jwtToken: string | null) => {
    tokenCookie.value = jwtToken
    token.value = jwtToken
  }

  const loggedIn = computed(() => !!user.value)

  const redirectTo = useState('authRedirect')

  addRouteMiddleware(
    'auth',
    (to) => {
      // Redirect to login if user is not logged in
      if (to.meta.auth && !loggedIn.value) {
        redirectTo.value = to.path
        return localePath('/login')
      }
      // Redirect to profile if user is logged in and trying to access login page
      if (loggedIn.value && to.path == localePath('/login')) {
        return localePath('profile')
      }
    },
    {
      global: true,
    }
  )

  const currentRoute = useRoute()

  if (import.meta.client) {
    watch(loggedIn, async (loggedIn) => {
      if (!loggedIn && currentRoute.meta.auth) {
        redirectTo.value = currentRoute.path
        await navigateTo(localePath('/login'))
      }

      if (loggedIn && currentRoute.path === localePath('/login')) {
        await navigateTo(redirectTo.value ?? localePath('profile'))
      }

      redirectTo.value = null
    })
  }

  return {
    provide: {
      auth: {
        user,
        loggedIn,
        redirectTo,
        loading,
        getResetPasswordLink,
        resetPassword,
        refreshUser,
        signInWithBookingRefEmail,
        signInWithEmailPassoword,
        signUp,
        signOut,
        setTokenCookie,
        getUserStatus,
        forgotBookingReference,
        updateUserInfo,
      },
    },
  }
})
