import { tv } from 'tailwind-variants'

export const header = {
  alpha: tv({
    slots: {
      logo: 'h-[35px] md:h-[50px]',
      logoutButton:
        'flex flex-row items-center gap-x-2 text-brand-secondary-400 text-md md:text-lg',
      logoutIcon: 'text-brand-danger-400 size-5 lg:size-6 ',
      background: 'bg-brand-secondary-100',
      container:
        'bg-brand-white w-full rounded-bl-[50px] lg:rounded-bl-[100px] px-sm drop-shadow-md',
      link: '',
    },
    base: 'h-20 lg:h-28 flex flex-row items-center justify-between cp-container-xl',
  }),
  bravo: tv({
    slots: {
      logo: 'h-[35px] md:h-[50px]',
      logoutButton:
        'flex flex-row items-center gap-x-2 text-brand-secondary-400 text-md md:text-lg',
      logoutIcon: 'text-brand-danger-400 size-5 lg:size-6 ',
      background: 'bg-brand-secondary-100',
      container:
        'bg-brand-white w-full rounded-bl-[50px] lg:rounded-bl-[100px] px-sm drop-shadow-md',
      link: '',
    },
    base: 'h-20 lg:h-28 flex flex-row items-center justify-between cp-container-xl',
  }),
  charlie: tv({
    slots: {
      logo: 'h-[35px] md:h-[50px]',
      logoutButton: 'flex flex-row items-center gap-x-2 font-link16',
      logoutIcon: 'text-brand-danger-400 size-5 lg:size-6 ',
      background: 'bg-brand-secondary-100',
      container: 'bg-brand-white w-full px-sm drop-shadow-md',
      link: 'font-link14 sm:font-link16',
    },
    base: 'h-20 lg:h-28 flex flex-row items-center justify-between xl:container sm:px-sm',
  }),
}
