import type { Pinia } from 'pinia'
import Toast, {
  type PluginOptions,
  POSITION,
  useToast,
} from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    shareAppContext: true,
    timeout: 5000,
    toastClassName: 'toast',
  }

  nuxtApp.vueApp.use(Toast, options)

  // provides a ToastInterface to every pinia store
  const pinia = usePinia() as Pinia
  const toast = useToast()
  const piniaPlugin = () => {
    return {
      toast: toast,
    }
  }
  pinia.use(piniaPlugin)
})
