import { tv } from 'tailwind-variants'

export const button = {
  alpha: tv({
    base: 'inline-flex flex-row font-semibold rounded-lg justify-center items-center',
    variants: {
      color: {
        primary:
          'bg-brand-primary-500 hover:bg-brand-primary-400 active:bg-brand-primary-600 text-brand-white',
        secondary:
          'bg-brand-white text-brand-secondary-500 outline outline-2 outline-brand-secondary-500 hover:bg-brand-secondary-200 active:bg-brand-secondary-300',
        alternative:
          'bg-brand-secondary-100 text-brand-secondary-500 outline outline-2 outline-brand-secondary-400 hover:bg-brand-secondary-200 active:bg-brand-secondary-300',
      },
      outline: {
        true: 'bg-transparent outline outline-2',
      },
      disabled: {
        true: 'opacity-50 pointer-events-none',
      },
      wide: {
        true: 'w-full',
      },
      loading: {
        true: 'opacity-75 pointer-events-none',
      },
      size: {
        xs: 'px-2 py-2 text-sm gap-1',
        sm: 'px-5 py-3 text-md gap-2',
        md: 'px-5 py-4 text-lg gap-3',
        lg: 'px-5 py-4 text-xl gap-4',
      },
    },

    compoundVariants: [
      {
        color: 'primary',
        outline: true,
        class:
          'text-brand-primary-500 outline-brand-primary-500 hover:bg-brand-primary-500 hover:text-brand-white active:bg-brand-primary-600',
      },
    ],

    defaultVariants: {
      color: 'primary',
      size: 'md',
    },
  }),
  bravo: tv({
    base: 'inline-flex flex-row font-bold  rounded-sm justify-center items-center transition-all',
    variants: {
      color: {
        primary:
          'bg-brand-primary-500 hover:bg-brand-primary-600 active:bg-brand-primary-700 text-brand-white border border-brand-primary-900 shadow-[inset_2px_2px_0_0_#fbd7a1,3px_3px_0_0_rgba(0,0,0,0.1)] active:shadow-[inset_2px_2px_0_0_#fbd7a1] [text-shadow:_0_1px_0_#C77C0A]',
        secondary:
          'bg-brand-secondary-400 text-brand-white border border-brand-secondary-700 hover:bg-brand-secondary-500 active:bg-brand-secondary-700 shadow-[inset_2px_2px_0_0_#95d8e0,3px_3px_0_0_rgba(0,0,0,0.1)] active:shadow-[inset_2px_2px_0_0_#95d8e0] [text-shadow:_0_1px_0_#006572]',
        alternative:
          'font-normal text-brand-secondary-600 border border-brand-secondary-400 bg-brand-gray-100 hover:bg-brand-gray-200 active:bg-brand-gray-300 shadow-[inset_2px_2px_0_0_#ffffff,3px_3px_0_0_rgba(0,0,0,0.1)] active:shadow-[inset_2px_2px_0_0_#ffffff]',
      },
      outline: {
        true: 'bg-transparent',
      },
      disabled: {
        true: 'opacity-50 pointer-events-none',
      },
      wide: {
        true: 'w-full',
      },
      loading: {
        true: 'opacity-75 pointer-events-none',
      },
      size: {
        xs: 'px-2 py-2 text-lg gap-1',
        sm: 'px-5 py-2 text-xl gap-2',
        md: 'px-5 py-3 text-xl gap-3',
        lg: 'px-5 py-3 text-xl gap-4',
      },
    },

    compoundVariants: [
      {
        color: 'primary',
        outline: true,
        class:
          'text-brand-primary-500 hover:bg-brand-primary-500 hover:text-brand-white active:bg-brand-primary-600 shadow-none hover:shadow-[inset_2px_2px_0_0_#fbd7a1,3px_3px_0_0_rgba(0,0,0,0.1)] border border-brand-primary-600',
      },
    ],

    defaultVariants: {
      color: 'primary',
      size: 'md',
    },
  }),
  charlie: tv({
    base: 'inline-flex flex-row rounded justify-center items-center transition-all font-heading font-medium',
    variants: {
      color: {
        primary:
          'bg-gradient-to-b from-brand-primary-500 hover:from-brand-primary-700 to-brand-primary-700 hover:to-brand-primary-900  active:from-brand-primary-900 active:to-brand-primary-1000 text-brand-white border border-brand-primary-700 hover:border-brand-primary-1000 active:border-brand-primary-1000',
        secondary:
          'bg-gradient-to-b from-brand-secondary-300 hover:from-brand-secondary-500 to-brand-secondary-500 hover:to-brand-secondary-700  active:from-brand-secondary-700 active:to-brand-secondary-900 text-brand-white border border-brand-secondary-500 hover:border-brand-secondary-800 active:border-brand-secondary-800',
        alternative:
          'bg-gradient-to-b from-brand-gray-100 hover:from-brand-gray-200 to-brand-gray-300 hover:to-brand-gray-600  active:from-brand-gray-400 active:to-brand-gray-700 text-brand-blue-900 border border-brand-gray-800 hover:border-brand-gray-800 active:border-brand-gray-1000',
      },
      outline: {
        true: 'bg-gradient-to-b from-brand-white to-brand-white ',
      },
      disabled: {
        true: 'opacity-50 pointer-events-none',
      },
      wide: {
        true: 'w-full',
      },
      loading: {
        true: 'opacity-75 pointer-events-none',
      },
      size: {
        xs: 'px-2 py-2 text-lg gap-1',
        sm: 'px-5 py-2 text-xl gap-2',
        md: 'px-5 py-3 text-xl gap-3',
        lg: 'px-5 py-3 text-xl gap-4',
      },
    },

    compoundVariants: [
      {
        color: 'primary',
        outline: true,
        class:
          'text-brand-primary-500 hover:text-brand-white hover:from-brand-primary-500 hover:to-brand-primary-700',
      },
    ],

    defaultVariants: {
      color: 'primary',
      size: 'md',
    },
  }),
}
