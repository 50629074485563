<script lang="ts" setup>
const localePath = useLocalePath()
const handleError = () => clearError({ redirect: localePath('/') })
const { t } = useI18n()
useHead({
  title: t('404-page.title'),
})
</script>

<template>
  <CPPageHeader />

  <main
    class="w-full h-screen relative items-center bg-gradient-to-b from-brand-secondary-400 to-brand-secondary-100 flex flex-col"
  >
    <div
      class="translate-y-[5vh] sm:translate-y-[15vh] flex flex-col gap-3 h-0 items-center text-center"
    >
      <span class="font-h1">{{ $t('404-page.page-gone') }}</span>
      <span class="font-p14">{{
        $t('404-page.start-over-or-go-to-the-frontpage')
      }}</span>
      <button
        class="bg-gradient-to-b font-h4 from-brand-primary-500 hover:from-brand-primary-700 to-brand-primary-700 hover:to-brand-primary-900 active:from-brand-primary-900 active:to-brand-primary-1000 text-brand-white border border-brand-primary-700 hover:border-brand-primary-1000 active:border-brand-primary-1000 py-xs px-lg"
        @click="handleError"
      >
        {{ $t('404-page.go-to-frontpage') }}
      </button>
    </div>
    <NuxtImg
      preload
      src="error.png"
      alt="Logo"
      width="100%"
      class="w-full self-end h-full object-cover"
    />
  </main>
</template>
