import { joinURL } from 'ufo'
import { createOperationsGenerator } from '#image'
import { type ProviderGetImage } from '@nuxt/image'

export const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: 'w',
    height: 'h',
    fit: 'c',
    position: 'g',
    background: 'bg',
    quality: 'q',
  },
  joinWith: '-',
  formatter: (key: string, value: string) => `${key}_${value}`,
})

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = useRuntimeConfig().public.NUXT_CDN_URL } = {}
) => {
  const fileFormat = src.substring(src.lastIndexOf('.') + 1)
  const srcWithoutFileFormat = src.substring(0, src.lastIndexOf('.'))

  const urlsToRemove = [
    'https://twenty-data.s3.eu-central-1.amazonaws.com/',
    'https://images.twentycdn.net/',
    'https://images.test.twentycdn.net/',
    'https://twenty-data-dev.s3.eu-central-1.amazonaws.com/',
  ]

  const regex = new RegExp(urlsToRemove.join('|'), 'g')

  const srcWithoutBaseUrls = srcWithoutFileFormat.replace(regex, '')

  const { format, ...restModifiers } = modifiers
  const operations = operationsGenerator(restModifiers)

  return {
    url: joinURL(
      baseURL,
      operations + `-tc_${fileFormat}` + `/${srcWithoutBaseUrls}` + `.${format}`
    ),
  }
}
