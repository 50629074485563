export default defineNuxtPlugin((nuxtApp) => {
  const $device = nuxtApp.$device
  const isIpad = ref(false)

  /*
    If the userAgent includes Mac we can assume it's an Apple device 
    The maxTouchPoints read-only property of the Navigator interface returns
    the maximum number of simultaneous touch contact points are supported by the current device.
  */

  if (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  )
    isIpad.value = true

  $device.isIpad = isIpad.value
})
