import type { UseFetchOptions } from 'nuxt/app'

export default async function useTwentyFetch<T>(
  url: ComputedRef<string> | string,
  apiVersion?: 'v1' | 'v2',
  opts: UseFetchOptions<T> = {}
) {
  const { $twentyv1, $twentyv2 } = useNuxtApp()

  return useFetch(url, {
    ...opts,
    $fetch: apiVersion === 'v1' ? $twentyv1 : $twentyv2,
    getCachedData(key, nuxtApp) {
      // only enable browserCache for request with a key specified in options
      if (opts.key) {
        return nuxtApp.payload.data[key]
      }

      // default useFetch behavior
      return nuxtApp.isHydrating
        ? nuxtApp.payload.data[key]
        : nuxtApp.static.data[key]
    },
  })
}
