<script setup lang="ts">
import { tv } from 'tailwind-variants'
import type { RouteLocationRaw } from '#vue-router'
import { button } from './buttonStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof button
const defaultButton = button[theme]

interface ButtonProps {
  color?: 'primary' | 'secondary' | 'alternative'
  outline?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  disabled?: boolean
  wide?: boolean
  to?: RouteLocationRaw
  loading?: boolean
  leadingIcon?: string
  trailingIcon?: string
  unstyled?: boolean
}

const props = defineProps<ButtonProps>()

const localePath = useLocalePath()

const ui = computed(() =>
  tv({
    extend: defaultButton,
  })({
    outline: props.outline,
    color: props.color,
    size: props.size,
    disabled: props.disabled,
    wide: props.wide,
    loading: props.loading,
  })
)

const component = computed(() => {
  if (props.to) return resolveComponent('NuxtLink')
  return 'button'
})
</script>

<template>
  <component
    v-bind="props"
    :is="component"
    :class="unstyled ? null : ui"
    :to="to ? localePath(to) : null"
  >
    <Spinner v-if="loading" />
    <template v-else>
      <slot name="leading-icon" />
      <slot />
      <slot name="trailing-icon" />
    </template>
  </component>
</template>
