export enum LoginView {
  signIn = 'sign-in',
  password = 'password',
  createPassword = 'create-password',
  forgotPassword = 'forgot-password',
  checkInbox = 'check-inbox',
  signInAttemptsExceeded = 'sign-in-attempts-exceeded',
  bookingSignIn = 'booking-sign-in',
  forgotBookingReference = 'forgot-booking-reference',
  resetPassword = 'reset-password',
}

export enum BookingStatus {
  fullyPaid = 'PAYMENT_OK',
  partiallyPaid = 'PARTIALLY_PAID',
  overPaid = 'PAID_TOO_MUCH',
  notPaid = 'NO_PAYMENT_MADE',
}

export enum paymentRateStatus {
  pending = 'pending',
  paid = 'paid',
  cancelled = 'cancelled',
}

export enum AuthType {
  booking = 'booking',
  user = 'web_user',
}

export enum UserType {
  customer = 'customer',
  agent = 'agent',
}

export enum ParticipantsEditSetting {
  changesNotAllowed = 'CHANGES_NOT_ALLOWED',
  tooCloseToArrival = 'CHANGES_NOT_ALLOWED_TOO_CLOSE_TO_ARRIVAL',
  existingOnlyAllowed = 'EXISTING_ONLY_ALLOWED',
  fullChangesAllowed = 'FULL_CHANGES_ALLOWED',
}

export enum EditProfileCategory {
  personal = 'personal',
  country = 'country',
  address = 'address',
  phone = 'phone',
}
