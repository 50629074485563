import type { NavItem } from '../../websites/types/Navigation'

export const whitelabelNavItem = (link: NavItem) => {
  const { whitelabel } = useNuxtApp().$i18n.localeProperties.value
  const localePath = useLocalePath()
  return whitelabel
    ? link.isExternal
      ? link.url
      : whitelabel + link.url
    : link.isExternal
    ? link.url
    : link.url && localePath(link.url)
}

export const whitelabelLink = (link: string) => {
  const localePath = useLocalePath()
  const { whitelabel } = useNuxtApp().$i18n.localeProperties.value
  return whitelabel ? whitelabel + link : localePath(link)
}
