export default defineAppConfig({
  brand: 'danskbilferie',
  theme: 'charlie',
  includeLocaleInRedirects: false,
  domainConfigurations: {
    da: {
      brand: 'danskbilferie',
      locale: 'dk',
      currency: 'DKK',
      safetyIcons: {
        headerRight: [],
        headerLeft: ['Rejsegarantix2', 'DRFx2'],
        footer: {
          security: ['Rejsegaranti', 'DRFx2'],
          center: 'secure',
          payment: ['mastercard', 'visa', 'dankort', 'mobilepay'],
        },
      },
      socialIcons: [
        {
          link: 'https://www.facebook.com/FriferieAS',
          icon: 'facebook',
        },
        {
          link: 'https://www.instagram.com/friferie/',
          icon: 'instagram',
        },
      ],
      sortingOptions: {
        sites: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
          {
            orderBy: 'reviews',
            order: 'desc',
            label: 'review-desc',
          },
        ],
        accommodations: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
        ],
        reviewSorting: [
          'most-helpful',
          'highest-grade',
          'lowest-grade',
          'oldest-newest',
          'newest-oldest',
        ],
        reviewGroupSorting: [
          'couple',
          'family-with-youngest-child-higher-6-years',
          'family-with-youngest-child-lower-6-years',
          'group-of-friends',
          'solo',
        ],
      },
    },
  },
})
