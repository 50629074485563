function gtagEvent(event: string, data?: object) {
  const gtag = useGtag().gtag
  gtag('event', event, data)
}

function getDaysTill(targetDate: string) {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const fromDate = new Date(targetDate)
  const tillDate = Date.now()

  return Math.round(Math.abs((Number(fromDate) - tillDate) / oneDay))
}

function getWeekNumberFromDate(date: string) {
  const currentDate = new Date(date)
  const year = new Date(currentDate.getFullYear(), 0, 1)
  const days = Math.floor(
    (Number(currentDate) - Number(year)) / (24 * 60 * 60 * 1000)
  )
  return Math.ceil((currentDate.getDay() + 1 + days) / 7)
}
const percentage = (partialValue: number, totalValue: number) => {
  return Math.round((100 * partialValue) / totalValue)
}

export default {
  gtagEvent,
  getDaysTill,
  getWeekNumberFromDate,
  percentage,
}
